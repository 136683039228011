import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { SimpleUser, SimpleUserOptions } from "sip.js/lib/platform/web";
import { env } from 'process';

// Helper function to get an HTML audio element
function getAudioElement(id: string): HTMLAudioElement {
  const el = document.getElementById(id);
  if (!(el instanceof HTMLAudioElement)) {
    throw new Error(`Element "${id}" not found or not an audio element.`);
  }
  return el;
}

// Helper function to wait
async function wait(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

// Main function
async function init(): Promise<SimpleUser> {

  const environments: any = {
    fastmeet: {
      webrtcPort: '8089',
      host: 'ivr1.sourcebox.io', // webrtc host and sip host are the same
      sipPort: "5060", // fastmeet
      sipDest: 'multi'  
    }
  };

  // const env: string = String(process.env['REACT_APP_ENV']);
  const config: any = environments['fastmeet'];
  
  const sipUser = 'webrtc_client';
  const sipPass = 'webrtc_client';

  // console.log(config);

  // SIP over WebSocket Server URL
  // The URL of a SIP over WebSocket server which will complete the call.
  // FreeSwitch is an example of a server which supports SIP over WebSocket.
  // SIP over WebSocket is an internet standard the details of which are
  // outside the scope of this documentation, but there are many resources
  // available. See: https://tools.ietf.org/html/rfc7118 for the specification.
  const server = "wss://" + config.host + ":" + config.webrtcPort + "/ws";

  // SIP Request URI
  // The SIP Request URI of the destination. It's "Who you wanna call?"
  // SIP is an internet standard the details of which are outside the
  // scope of this documentation, but there are many resources available.
  // See: https://tools.ietf.org/html/rfc3261 for the specification.
  const destination = "sip:" + config.sipDest + "@" + config.host + ":" + config.sipPort;

  // SIP Address of Record (AOR)
  // This is the user's SIP address. It's "Where people can reach you."
  // SIP is an internet standard the details of which are outside the
  // scope of this documentation, but there are many resources available.
  // See: https://tools.ietf.org/html/rfc3261 for the specification.
  const aor = "sip:" + sipUser + "@" + config.host + ":" + config.sipPort;

  // SIP Authorization Username
  // This is the user's authorization username used for authorizing requests.
  // SIP is an internet standard the details of which are outside the
  // scope of this documentation, but there are many resources available.
  // See: https://tools.ietf.org/html/rfc3261 for the specification.
  const authorizationUsername = sipUser; //'webrtc_client';

  // SIP Authorization Password
  // This is the user's authorization password used for authorizing requests.
  // SIP is an internet standard the details of which are outside the
  // scope of this documentation, but there are many resources available.
  // See: https://tools.ietf.org/html/rfc3261 for the specification.
  const authorizationPassword = sipPass; //'webrtc_client';

  // Configuration Options
  // These are configuration options for the `SimpleUser` instance.
  // Here we are setting the HTML audio element we want to use to
  // play the audio received from the remote end of the call.
  // An audio element is needed to play the audio received from the
  // remote end of the call. Once the call is established, a `MediaStream`
  // is attached to the provided audio element's `src` attribute.
  const options: SimpleUserOptions = {
    aor,
    media: {
      remote: {
        audio: getAudioElement("remoteAudio")
      }
    },
    userAgentOptions: {
      authorizationPassword,
      authorizationUsername,
      logBuiltinEnabled: false,
      logLevel: 'warn'
    }
  };

  // Construct a SimpleUser instance
  const simpleUser = new SimpleUser(server, options);

  // Supply delegate to handle inbound calls (optional)
  simpleUser.delegate = {
    onCallReceived: async () => {
      await simpleUser.answer();
    }
  };

  // Connect to server
  await simpleUser.connect();

  // Register to receive inbound calls (optional)
  //await simpleUser.register();

  // Place call to the destination
  await simpleUser.call(destination);

  // Wait some number of milliseconds
  //await wait(30000);

  // Hangup call
  //await simpleUser.hangup();

  return simpleUser;
}

function App() {

  const [simpleUser, setSimpleUser] = useState<SimpleUser | undefined>(undefined);
  const [showDialpad, setShowDialpad] = useState<string>('d-none');
  const [showDial, setShowDial] = useState<string>('');
  const [showHangup, setShowHangup] = useState<string>('d-none');    

  useEffect(() => {
    function handleKeyDown(e: any) {
      const dtmfKey = '0123456789*#'.indexOf(e.key) !== -1;
      if (dtmfKey) {
        // console.log(e.key);
        if (simpleUser !== undefined) {
          simpleUser.sendDTMF(e.key);
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, [simpleUser]);

  function call() {
    // Run it
    init()
    .then((sipUser) => {
      // console.log(`Success`);
      // console.log("Our simpleUser: ", sipUser);
      setSimpleUser(sipUser);
      setShowDialpad('');
      setShowDial('d-none');
      setShowHangup('');
    })
    .catch((error: Error) => console.error(`Failure`, error));
  }

  function hangup() {
    // Hangup call
    if (simpleUser !== undefined) {
      simpleUser.hangup();
      setShowDialpad('d-none');
      setShowDial('');
      setShowHangup('d-none');
    }
  }

  function dtmf(digit: string) {
    if (simpleUser !== undefined) {
      simpleUser.sendDTMF(digit);
    }
  }

  return (
    <div>

      <div className="container text-center mt-4">
        <div className="row mb-4">
          <div className="col">
            <audio id="remoteAudio" controls>
              <p>Your browser doesn't support HTML5 audio.</p>
            </audio>          
          </div>
        </div>
      </div>

      <table className={"table table-bordered text-center App-keypad " + showDialpad}>
        <tbody>
        <tr>
          <td id="key-1" className="p-3" onClick={() => dtmf('1')}>1</td>
          <td id="key-2" className="p-3" onClick={() => dtmf('2')}>2</td>
          <td id="key-3" className="p-3" onClick={() => dtmf('3')}>3</td>
        </tr>
        <tr>
          <td id="key-4" className="p-3" onClick={() => dtmf('4')}>4</td>
          <td id="key-5" className="p-3" onClick={() => dtmf('5')}>5</td>
          <td id="key-6" className="p-3" onClick={() => dtmf('6')}>6</td>
        </tr>
        <tr>
          <td id="key-7" className="p-3" onClick={() => dtmf('7')}>7</td>
          <td id="key-8" className="p-3" onClick={() => dtmf('8')}>8</td>
          <td id="key-9" className="p-3" onClick={() => dtmf('9')}>9</td>
        </tr>
        <tr>
          <td id="key-star" className="p-3" onClick={() => dtmf('*')}>*</td>
          <td id="key-0" className="p-3" onClick={() => dtmf('0')}>0</td>
          <td id="key-hash" className="p-3" onClick={() => dtmf('#')}>#</td>
        </tr>

        </tbody>
        
      </table>

      <div className="container">
        <div className="row">
          <div className={"col col-sm-12 col-sm-12 text-center " + showDial}>
            <button className="mr-3 btn btn-success" onClick={call}><i className="fas fa-phone-alt"></i> Connect</button>
          </div>
          <div className={"col col-md-12 col-sm-12 text-center " + showHangup}>
            <button className="btn btn-danger" onClick={hangup}><i className="fas fa-phone-slash"></i> Disconnect</button>          
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

/*

    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
*/